<template>
	<div class="margin">
		<div class="infoBlock--fourCol container afterBanner">
			<div class="infoBlock" v-for="item in items" :class="{withLink: item.link}">
				<router-link :to="item.link" v-if="item.link"></router-link>
				<div class="infoBlock__content">
					<p class="infoBlock--title" v-html="item.title"></p>
					<p class="infoBlock--text" v-html="item.text"></p>
				</div>
			</div>
		</div>
	</div>
</template>



<style lang="sass">
	@import '@/assets/sass/InfoBlocks'
</style>

<script>
	
	export default {
		props: ['items'],
		methods: {
			resize: function(){
				var infoBlockContainer = document.getElementsByClassName('infoBlock--fourCol');
				infoBlockContainer.forEach(function(container) {
					var items = container.getElementsByClassName('infoBlock__content');
					var height = 0;
					items.forEach(function(item) {
						item.style.height = "";
					});
					items.forEach(function(item) {
						if(item.offsetHeight > height){
							height = item.offsetHeight;
						}
					});
					items.forEach(function(item) {
						item.style.height = height + "px";
					});
				});
			}
		},
		mounted(){
			var comp = this;
			window.addEventListener('resize', function() {
				comp.resize();
			});
			comp.resize();
		}
	}
</script>